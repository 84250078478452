(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset/components/maintenance-view/assets/javascripts/maintenance-view.js') >= 0) return;  svs.modules.push('/modules/oddset/components/maintenance-view/assets/javascripts/maintenance-view.js');
"use strict";

const {
  header,
  body
} = svs.oddset.data.maintenanceConfig;
const MaintenanceView = () => React.createElement("div", {
  className: "oddset-maintenance-view"
}, React.createElement("div", {
  className: "oddset-maintenance-view__left"
}), React.createElement("div", {
  className: "oddset-maintenance-view__right"
}, React.createElement("h2", null, header), React.createElement("h4", null, body), React.createElement("p", null, "Spel fr\xE5n Svenska Spel & Casino AB")));
setGlobal('svs.oddset.components.maintenance_view.MaintenanceView', MaintenanceView);

 })(window);